<template>
    <div class="row">
        <div class="col-12 col-lg-8">
            <div class="card-info">
                <p class="title">
                    <slot name="title" />
                </p>
            </div>
            <div class="description">
                <slot name="description"></slot>
            </div>
            <div class="row important-informations">
                <div class="col-12 col-lg-4 program-info">
                    <div class="program-title">
                        Cрок обучения
                    </div>
                    <div class="program-infos">
                        <slot name="duree"></slot>
                    </div>
                </div>
                <div class="col-12 col-lg-4 program-info">
                    <div class="program-title">
                        Язык обучения
                    </div>
                    <div class="program-infos">
                        <slot name="language"></slot>
                    </div>
                </div>
                <div class="col-12 col-lg-4 program-info">
                    <div class="program-title">
                        Стоимость
                    </div>
                    <div class="program-infos">
                        <slot name="cost"></slot>
                    </div>
                </div>
            </div>

            <div class="row bottom-informations">
                <div class="col-12 program-info">
                    <div class="program-title">
                        Требования к поступлению:
                        <span class="details">
                            <slot name="requirements"></slot>
                        </span>
                    </div>
                </div>

                <div class="col-12 program-info">
                    <div class="program-title">
                        Кампусы:
                        <span class="details">
                            <slot name="campus"></slot>
                        </span>
                    </div>
                </div>

                
            
                <div class="col-12 program-info">
                    <div class="program-title">
                        Начало обучения:
                        <span class="details">
                            <slot name="start-time"></slot>
                        </span>
                    </div>
                </div>

                <div class="col-12 program-info">
                    <div class="program-title">
                        Средняя зарплата выпускника:
                        <span class="details">
                            <slot name="salary"></slot>
                        </span>
                    </div>
                </div>
            </div>

            <div v-if="false" class="row specialisation">
                <div class="col-12" style="background-color: #f6f6f6;">
                    <div style="padding:2rem">
                        <h5>
                        Специализации
                        </h5>
                        <ul>
                            <li>ACCOUNTING</li>
                            <li>ARTIFICIAL INTELLIGENCE</li>
                            <li>CORPORATE FINANCE</li>
                            <li>E-COMMERCE AND DIGITAL STRATEGY</li>
                            <li>ENGINEERING SCIENCE & INNOVATION MANAGEMENT</li>
                            <li>ENTREPRENEURSHIP & BUSINESS ADMINISTRATION</li>
                            <li>GLOBAL BUSINESS</li>
                            <li>INTERNATIONAL BUSINESS</li>
                            <li>LUXURY BRAND MANAGEMENT</li>
                            <li>MARINE & ENVIRONMENTAL MANAGEMENT</li>
                            <li>MARKETING</li>
                            <li>SUPPLY CHAIN</li>
                            <li>SUSTAINABLE DEVELOPMENT & SOCIAL RESPONSIBILITY</li>
                        </ul>
                    </div>
                    
                </div>
            </div>

            <div v-if="false" class="row work">
                <div class="col-12 h5-wrapper">
                    <h5>
                        Где работают выпускники?
                    </h5>
                </div>
                
                <div class="col-12 col-lg-6">
                    <p class="title">Сферы</p>
                    <ul>
                        <li>Marketing and communications 29%</li>
                        <li>Business development and sales 25%</li>
                        <li>Consulting, audit, finance 18%</li>
                        <li>Sustainable development, CSR 8%</li>
                        <li>Project management, digital and IT 8% </li>
                        <li>Executive management, entrepreneurship 7%</li>
                        <li>Purchasing & logistics 4%</li>
                        <li>Others 1% </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-6">
                    <p class="title">Компании</p>
                    <p>
                        Abbott, Amazon, Auchan, Banque Nationale du Canada, Bayer, CBE Group Chemical Assistance, 
                        Ingenico, L’Oréal, Marine nationale, PwC, Tesla, The Purpose Group, Coca-Cola, Epicerie verte, EY,
                        Ferrari, Forton Cushman and Wakefield, Google, Industrial Bank Co., Ltd.
                    </p>
                </div>
                
                <div class="col-12">
                    <div class="">
                        Рейтинг трудоустройства в течение 6 месяцев после окончания обучения
                        - <b> 91% </b>
                    </div>
                </div>
            </div>

            <div v-if="false" class="row specialisation">
                <div class="col-12">
                    <h5>
                        Программа обучения
                    </h5>
                </div>
                <div class="col-12">
                    img
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-4">
            <div class="row cta-wrapper">
                <div class="col-12">
                    
                </div>
                <div class="col-12">
                        <div class="introduction-cta row">
                        <div v-if="this.$props.url" class="col-12 brochure-wrapper">
                            <div class="cta-wrap">
                                <p class="cta-title">Подробно</p>
                                <div v-if="this.isProcessingDownload" class="spinner-grow" style="color:white">
                                </div>
                                <div v-if="this.hasMadeDownload">
                                    <p style="color:white">
                                        Готово! Открыть <a style="color:white;" :href="this.$store.state.downloadLink" target="_blank">PDF файл</a>
                                    </p>
                                </div>
                                <button v-if="!this.isProcessingDownload && !this.hasMadeDownload" type="button" :data-brochure="this.$props.url" @click="downloadTheBrochure" class="btn btn-light buttonDownloadBrochure">
                                    Брошюра
                                    <img src="@/assets/download.svg" class="img-brochure" alt="">
                                </button>
                            </div>
                        </div>
                        
                        <div class="col-12 contact-wrapper">
                            <div class="cta-wrap">
                                <p class="cta-title">Задайте ваши вопросы</p>
                                <a href="https://t.me/tani_paris" class="btn btn-light">
                                    написать <img src="@/assets/message.svg" class="img-contact" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            isProcessingDownload: false,
            hasMadeDownload : false
        }
    },
    props:['url'],
    computed:{
        ...mapState['downloadLink']
    },
    methods:{
        downloadTheBrochure(element){
            let brochurePath = element.target.dataset.brochure;
            if(!brochurePath){
                return;
            }
            this.isProcessingDownload = true;
            let getBrochure = this.$getBrochureByPath(brochurePath);
            getBrochure.then(
                ()=>{
                    this.isProcessingDownload = false;
                    this.hasMadeDownload =true;
                }
                
            )
        },
        ...mapMutations['updateDownloadLink']
    }
}
</script>

