<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1><slot name="title"></slot></h1>
                        </div>
                        
                        <div class="col-12 col-lg-6">
                            <div v-if="this.$slots.keyPoints" class="card-info">
                                <p class="title">Ключевые моменты</p>
                                <p class="details">
                                    <slot name="keyPoints"></slot>
                                </p>
                            </div>
                            <div v-if="this.$slots.orientation" class="card-info">
                                <p class="title">Направление</p>
                                <p class="details"><slot name="orientation"></slot></p>
                            </div>
                            <div v-if="this.$slots.speciality" class="card-info">
                                <p class="title">Специальность</p>
                                <p class="details"><slot name="speciality"></slot></p>
                            </div>
                            <div v-if="this.$slots.alumniSalary" class="card-info">
                                <p class="title">Заработная плата выпускников</p>
                                <p class="details">
                                    <slot name="alumniSalary"></slot>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="card-info">
                                <p class="title">Описание</p>
                                <p class="details" style="max-width:80%">
                                    <slot name="description"></slot>
                                </p>
                            </div>
                            <div v-if="this.$slots.youtube" class="youtube">
                                <slot name="youtube"></slot>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>