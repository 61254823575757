<template>
    <section >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title">
                        <h2>Кампусы</h2>
                    </div>
                </div>
            </div>
            <slot name="campuses"></slot>
        </div>
    </section>
</template>