<template>
    <div class="row campus-wrapper">
        <div class="col-12 col-lg-6 youtube-wrapper">
            <div class="youtube youtube h-100 d-flex flex-column justify-content-center align-items-center">
                <slot name="iframe-youtube"></slot>
            </div>
        </div>
        <div v-if="this.$slots.localisation" class="col-12 col-lg-6 card-wrapper">
            <div class="campus-card">
                <h3><slot name="localisation"></slot></h3>
                <p class="card-p">
                    <slot name="description"></slot>
                </p>
                <div class="cta-wrapper">
                    <button class="btn btn-light">Подсчитать бюджет</button>
                </div>
            </div>
        </div>
    </div>
</template>