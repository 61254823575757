<template>
    <section class="introduction">
        <div class="container h-100">
            <div class="row h-100 d-flex flex-column justify-content-center">
                <div class="col-12 col-lg-4 offset-lg-8">
                    <div class="introduction-school">
                        <h1><slot name="title"></slot></h1>
                        <div class="introduction-wrapper">
                            <p class="introduction-title">Специальность</p>
                            <p class="introduction-detail"><slot name="speciality"></slot></p>
                        </div>
                        <div class="introduction-wrapper">
                            <p class="introduction-title">Время обучения</p>
                            <p class="introduction-detail"><slot name="min-duration"></slot></p>
                        </div>
                        <div class="introduction-wrapper">
                            <p class="introduction-title">Стоимость обучения</p>
                            <p class="introduction-detail"><slot name="cost"></slot></p>
                        </div>
                        <div class="introduction-wrapper">
                            <p class="introduction-title">Кампусы</p>
                            <p class="introduction-detail"><slot name="campus"></slot></p>
                        </div>
                        <div class="introduction-cta d-flex flex-row justify-content-between">
                            <a target="_blank" :href="this.$props.url" id="sign-in-button" class="scroll-to-element btn btn-light">
                                Брошюра
                                <img class="img-brochure" src="@/assets/download.svg" alt="download brochure">
                            </a>
                            
                            <button class="btn btn-light">
                                написать
                                <img class="img-contact" src="@/assets/message.svg" alt="contact us">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props:['url']
}
</script>