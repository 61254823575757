<template>
    <section class="menu d-none d-lg-block">
        <div class="container h-100">
            <div class="row h-100 d-flex flex-column justify-content-center">
                <div class="d-flex flex-row justify-content-center">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <slot name="introduction"></slot>
                        </li>
                        <li class="nav-item">
                            <slot name="programs"></slot>
                        </li>
                        <li class="nav-item">
                            <slot name="campuses"></slot>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section class="menu d-lg-none" id="mobile-menu">
        <div class="container h-100">
            <div class="row h-100 d-flex flex-column justify-content-center">
                <div class="d-flex flex-row justify-content-center">
                    <slot name="selectSection"></slot>
                </div>
            </div>
        </div>
    </section>
</template>